import React from 'react';
import PropTypes from 'prop-types';
import IconCamera from 'cccisd-icons/camera';
import IconTrash from 'cccisd-icons/bin';
import IconCross from 'cccisd-icons/cross';
import IconPlus from 'cccisd-icons/plus2';
import IconWarning from 'cccisd-icons/warning2';
import { playerWrapper } from 'cccisd-survey-common';
import style from './style.css';

class CameraFlowPlayer extends React.Component {
    constructor(props) {
        if (props.isPreview) {
            props.field.onChange = () => {};
        }
        super(props);
        this.videoEl = React.createRef();
        this.componentEl = React.createRef();
        this.canvasEl = React.createRef();

        this.state = {
            captures:
                props.field && props.field.initialValue && Array.isArray(props.field.initialValue)
                    ? props.field.initialValue
                    : [],
            error: '',
            isCameraLoading: false, // prevents double clicks
            isCameraOn: false,
            retakeIndex: '',
        };
    }

    laravelHeaderNode = null;

    static propTypes = {
        field: PropTypes.object,
        settings: PropTypes.object,
        isPreview: PropTypes.bool,
        setParameters: PropTypes.func,
        parameters: PropTypes.object,
        goToNextPage: PropTypes.func,
        registerAction: PropTypes.func,
        accommodations: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
        isPreview: false,
    };

    componentDidMount() {
        let nodes = [...document.querySelector('body').childNodes];
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            // assuming the laravel header node is the first one to have a zIndex
            if (node && node.style && node.style.zIndex) {
                this.laravelHeaderNode = node;
                break;
            }
        }
        if (!this.laravelHeaderNode) {
            console.error('Could not find node for Laravel header; Camera may be positioned awkwardly.');
        }
        if (!this.props.field.initialValue && !this.props.field.value) {
            this.props.field.onChange([]);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.captures !== prevState.captures) {
            this.props.field.onChange(this.state.captures);
        }
    }

    openCamera = () => {
        this.setState({ isCameraLoading: true }, async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: { facingMode: 'environment' },
                });
                this.videoEl.current.srcObject = stream;
                await this.videoEl.current.play(); // will throw error if unallowed
                this.laravelHeaderNode.style.zIndex = '';
                this.allowScrolling(false);
                this.toggleParentsZIndex(false);

                if (!this.videoEl.current.paused) {
                    this.setState({ isCameraOn: true, isCameraLoading: false });
                } else {
                    throw new Error('Video stream could not autoplay');
                }
            } catch (error) {
                this.setState({ isCameraLoading: false, isCameraOn: false, error: error.message });
            }
        });
    };

    closeCamera = () => {
        if (this.videoEl.current.srcObject) {
            this.videoEl.current.srcObject.getTracks().forEach(t => t.stop());
        }
        this.videoEl.current.srcObject = null;
        this.laravelHeaderNode.style.zIndex = '2';
        this.allowScrolling(true);
        this.toggleParentsZIndex(true);
        this.setState({ isCameraOn: false, error: '', retakeIndex: '' });
    };

    allowScrolling = bool => {
        document.querySelector('body').style.overflow = bool ? 'visible' : 'hidden';
    };

    /* Must remove "z-index: 1" from all parent nodes so that z-index of camera
     * screen actually takes precedence over Progress Bar and Next button
     * In dev, className is "player__elementBlock" but in prod it's "elementBlock__"
     * https://coder-coder.com/z-index-isnt-working/   <-- lke scenario #4 here
     */
    toggleParentsZIndex = bool => {
        let node = this.componentEl.current;
        while (node.parentElement) {
            if ([...node.classList].find(c => /^player__elementBlock__/.test(c) || /^elementBlock__/.test(c))) {
                node.style.zIndex = bool ? '' : 'initial';
            }
            node = node.parentElement;
        }
    };

    capture = () => {
        const { retakeIndex, captures } = this.state;
        const canvas = this.canvasEl.current;
        const video = this.videoEl.current;
        const ratio = 0.5;
        canvas.width = video.videoWidth * ratio;
        canvas.height = video.videoHeight * ratio;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        const capture = {
            src: canvas.toDataURL('image/webp'),
            label: retakeIndex === '' ? '' : captures[retakeIndex].label,
            notes: retakeIndex === '' ? '' : captures[retakeIndex].notes,
        };

        if (retakeIndex === '') {
            this.setState({ captures: [...captures, capture] });
        } else {
            // need to clone array because splice modifies in place
            let updatedCaptures = [...captures];
            updatedCaptures.splice(retakeIndex, 1, capture);
            this.setState({ captures: updatedCaptures, retakeIndex: '' });
        }
        this.closeCamera();
    };

    handleDelete = index => {
        this.setState({ captures: this.state.captures.filter((_, i) => i !== index) });
    };

    handleRetake = retakeIndex => {
        this.setState({ retakeIndex }, () => {
            this.openCamera();
        });
    };

    handleChangeField = (fieldName, index, event) => {
        const capture = { ...this.state.captures[index] };
        capture[fieldName] = event.currentTarget.value;
        this.setState({ captures: this.state.captures.map((c, i) => (i === index ? capture : c)) });
    };

    render() {
        const {
            isPreview,
            settings: { instructionalText, warningText },
        } = this.props;
        const { captures, error, isCameraLoading, isCameraOn } = this.state;

        if (isPreview) {
            return (
                <div>
                    <IconCamera />
                    &nbsp;&nbsp;Say cheese!
                </div>
            );
        }

        return (
            <div ref={this.componentEl} className={style.flowStepWrapper}>
                <div style={{ display: isCameraOn ? 'flex' : 'none' }} className={style.videoContainer}>
                    <div className={style.closeCamera}>
                        <button className="btn" type="button" onClick={this.closeCamera}>
                            <IconCross />
                        </button>
                    </div>
                    <video autoPlay playsInline className={style.video} ref={this.videoEl} muted />
                    <div className={style.capture}>
                        <button type="button" onClick={this.capture} disabled={!isCameraOn}>
                            <span className={style.icon}>
                                <IconCamera />
                            </span>
                        </button>
                    </div>
                </div>

                <canvas ref={this.canvasEl} style={{ display: 'none' }} />

                <div>
                    {captures.length > 0 ? (
                        <div className={style.captureList}>
                            <h4>Photos</h4>
                            <ul>
                                {captures.map((capture, i) => (
                                    <li key={i}>
                                        <div className={style.imgRow}>
                                            <img src={capture.src} alt="camera capture" />
                                            <div>
                                                <button type="button" onClick={() => this.handleDelete(i)}>
                                                    <IconTrash />
                                                    &nbsp;&nbsp;Discard
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => this.handleRetake(i)}
                                                    disabled={isCameraLoading}
                                                >
                                                    <IconCamera />
                                                    &nbsp;&nbsp;Retake
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor={'label_capture_' + 1}>Label:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={'label_capture_' + i}
                                                value={capture.label}
                                                onChange={e => this.handleChangeField('label', i, e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor={'label_notes_' + 1}>Notes:</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                style={{ resize: 'none' }}
                                                id={'label_notes_' + i}
                                                value={capture.notes}
                                                onChange={e => this.handleChangeField('notes', i, e)}
                                            />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <p className={style.instructionalText}>{instructionalText}</p>
                    )}
                    <button
                        type="button"
                        id="showVideo"
                        onClick={this.openCamera}
                        className={style.addPhotoBtn}
                        disabled={isCameraLoading || captures.length >= 3}
                    >
                        <IconPlus />
                        &nbsp;&nbsp;Add Photo
                    </button>
                    {captures.length >= 1 && (
                        <p className={style.maxCaptures}>
                            <i>
                                {captures.length < 3
                                    ? 'You may add up to 3 photos.'
                                    : 'You have added the maximum of 3 photos for ' +
                                      'this observation. To add a different photo, you ' +
                                      'may discard or retake one of the existing photos.'}
                            </i>
                        </p>
                    )}
                    <p className={'text-danger ' + style.warning}>
                        <IconWarning />
                        &nbsp;&nbsp;{warningText}
                    </p>
                </div>
                {error && (
                    <p className={'text-danger ' + style.warning}>
                        <b>ERROR:</b> {error}
                    </p>
                )}
            </div>
        );
    }
}

/**
 * validate the step
 * settings {Object} - data from builder
 * value {Object} - data from component field prop
 */
const validate = (value, settings) => {
    // NEVER WILL BE REQUIRED
    // if (!value) {
    //     return 'Please wait';
    // }
    return null;
};

export default playerWrapper({ validate })(CameraFlowPlayer);
