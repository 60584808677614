import React from 'react';

export const fields = ['instructionalText', 'warningText'];

export const initialValues = {
    instructionalText:
        'If needed, help share the extent of the problem by documenting an image of the issue.',
    warningText: 'Avoid taking pictures with people or faces in them.',
};

export const icon = <i className="glyphicon glyphicon-camera" />;

export const getDataDefinitions = {};

export const validate = values => {
    return {};
};
