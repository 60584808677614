import React, { useState, useEffect } from 'react';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import style from './style.css';
import _get from 'lodash/get';
import IconCheckmark from 'cccisd-icons/checkmark4';
import IconPlus from 'cccisd-icons/plus2';
import IconQuestion from 'cccisd-icons/question4';
import Loader from 'cccisd-loader';
import Tooltip from 'cccisd-tooltip';
import { Html } from 'cccisd-wysiwyg';
import icons from './icons';

const WalkthroughNavMediator = props => {
    const {
        flowList,
        isNetworkError,
        onAlreadyComplete,
        onFlowStarted,
        onFlowComplete,
        onAssignmentComplete,
        onComplete,
        mediatorProps: { tooltips },
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [currentFlowHandle, setCurrentFlowHandle] = useState(null);
    const [completedFlows, setCompletedFlows] = useState(flowList.filter(f => f.isComplete).map(f => f.handle));

    useEffect(() => {
        if (flowList.every(f => !f.isRequired || f.isComplete)) {
            onAlreadyComplete();
        }
    }, []);

    useEffect(() => {
        if (currentFlowHandle) {
            onFlowStarted(flowList.find(f => f.handle === currentFlowHandle));
        }
    }, [currentFlowHandle]);

    function getPlayerProps() {
        const currentFlow = flowList.find(f => f.handle === currentFlowHandle);
        const playerProps = {
            ...currentFlow,
            onComplete: async () => {
                setIsLoading(true);
                await onFlowComplete(currentFlow);
                setCompletedFlows([...completedFlows, currentFlowHandle]);
                setCurrentFlowHandle(null);
                setIsLoading(false);
            },
            goBack: () => setCurrentFlowHandle(null),
        };
        return playerProps;
    }

    async function handleCompleteWalkthrough() {
        await onAssignmentComplete();
        await onComplete();
    }

    if (isLoading) {
        return <Loader loading />;
    }

    if (currentFlowHandle) {
        return (
            <DefaultPlayerSettingsContext.Provider
                value={{
                    background: 'solidColor',
                    backgroundColor: '#fff',
                    height: '600px',
                    adjustedPlayerWrapperClassName: style.player,
                }}
            >
                <div>
                    <Player key={currentFlowHandle} {...getPlayerProps()} isNetworkError={isNetworkError} />
                </div>
            </DefaultPlayerSettingsContext.Provider>
        );
    }

    const isUnassignedComplete = completedFlows.includes(flowList[flowList.length - 1].handle);
    return (
        <div>
            <ul className={style.flowList}>
                <h4>Assigned Observations</h4>
                {flowList.map((f, i) => {
                    if (i === flowList.length - 1) {
                        return null;
                    }

                    const isComplete = completedFlows.includes(f.handle);
                    const title = _get(f, 'flowSettings.playerTemplateSettings.title', null);
                    const iconSrc = icons[_get(f, 'surveySettings.iconKey', '')] || icons[title];

                    let tooltipHtml = _get(f, 'surveySettings.tooltipKey', '') || null;
                    if (!tooltipHtml) {
                        tooltipHtml = tooltips[title];
                    }

                    return (
                        <li key={f.flowId}>
                            <span className={style.iconSpan}>
                                {iconSrc && <img src={iconSrc} className={style.areaIcon} alt={title} />}
                            </span>
                            <h5>
                                {title}
                                {tooltipHtml && (
                                    <Tooltip title={<Html content={tooltipHtml} />}>
                                        <span className={style.tooltip}>
                                            <IconQuestion />
                                        </span>
                                    </Tooltip>
                                )}
                            </h5>
                            {isComplete ? (
                                <span className={style.iconCheckmark}>
                                    <IconCheckmark />
                                </span>
                            ) : (
                                <button
                                    type="button"
                                    className={style.startButton}
                                    onClick={() => setCurrentFlowHandle(f.handle)}
                                >
                                    Start
                                </button>
                            )}
                        </li>
                    );
                })}
            </ul>
            <div className={style.rightAlign}>
                {isUnassignedComplete ? (
                    <h5>
                        <span className={style.iconCheckmark}>
                            <IconCheckmark />
                        </span>
                        &nbsp;&nbsp;Unassigned Observation
                    </h5>
                ) : (
                    <button
                        className={style.startButton}
                        type="button"
                        onClick={() => setCurrentFlowHandle(flowList[flowList.length - 1].handle)}
                    >
                        <IconPlus />
                        &nbsp;&nbsp;Add Unassigned Observation
                    </button>
                )}
            </div>
            {flowList.every(f => !f.isRequired || completedFlows.includes(f.handle)) && (
                <div className={style.rightAlign}>
                    <button
                        className={'btn btn-primary ' + style.completeWalkthrough}
                        type="button"
                        onClick={handleCompleteWalkthrough}
                    >
                        Complete Walkthrough
                    </button>
                </div>
            )}
        </div>
    );
};

export default WalkthroughNavMediator;
