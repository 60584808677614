import thumbnail from './logo.png';
import Settings from './Settings';
import Mediator from './Mediator';

export default {
    handle: 'walkthrough',
    label: 'Walkthrough',
    thumbnail,
    settingsComponent: Settings,
    mediatorComponent: Mediator,
};
