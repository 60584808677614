import bathroom from './bathroom.png';
import cafeteria from './cafeteria.png';
import classroom from './classroom.png';
import gym from './gym.png';
import hallway from './hallway.png';
import hotspot from './hotspot.png';
import pickupArea from './pickup.png';
import schoolGrounds from './schoolGrounds.png';
import stairwell from './stairwell.png';
import studentEntrance from './studentEntrance.png';

export default {
    Bathroom: bathroom,
    Cafeteria: cafeteria,
    Classroom: classroom,
    Hallway: hallway,
    'Pick-up Area': pickupArea,
    'School Grounds': schoolGrounds,
    Stairwells: stairwell,
    'Student Entrance': studentEntrance,
    'Gym/Playing Field': gym,
    Other: hotspot,
};
