import React, { useState } from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import IconDown from 'cccisd-icons/arrow-down5';
import IconUp from 'cccisd-icons/arrow-up5';
import settingsWrapper from 'cccisd-laravel-assignment/dist/helpers/settingsWrapper';
import style from './style.css';

export const initialValues = {
    sidebarTitleEn: 'Title',
    sidebarTitleEs: 'Título',
    mustTakeInOrder: true,
    enablePrintMode: false,
    tooltips: {
        Bathroom: '',
        Cafeteria: '',
        Classroom: '',
        Hallway: '',
        'Pick-up Area': '',
        'School Grounds': '',
        Stairwells: '',
        'Student Entrance': '',
        'Gym/Playing Field': '',
    },
};

const Settings = ({ questSettings }) => {
    const [isTooltipExpanded, setIsTooltipExpanded] = useState(false);

    return (
        <>
            <div
                style={{ maxHeight: isTooltipExpanded ? 'initial' : '44px' }}
                className={style.tooltipContainer}
            >
                <h4>
                    <button
                        type="button"
                        className={style.expandButton}
                        onClick={() => setIsTooltipExpanded(prev => !prev)}
                    >
                        Tooltips&nbsp;
                        {isTooltipExpanded ? <IconUp /> : <IconDown />}
                    </button>
                </h4>
                {[
                    'Bathroom',
                    'Cafeteria',
                    'Classroom',
                    'Hallway',
                    'Pick-up Area',
                    'School Grounds',
                    'Stairwells',
                    'Student Entrance',
                    'Gym/Playing Field',
                    'Other',
                ].map(title => (
                    <Field key={title} name={`tooltips.${title}`}>
                        {formikBag => {
                            // Errors-out without explicitly changing from null to empty string
                            if (!formikBag.field.value) {
                                formikBag.field.value = '';
                            }
                            return <CccisdWysiwyg {...formikBag} label={title} />;
                        }}
                    </Field>
                ))}
            </div>
        </>
    );
};

export default settingsWrapper({ initialValues })(Settings);
