import builder from './Builder';
import {
    icon,
    initialValues as builderInitialValues,
    getDataDefinitions,
} from './Builder/settings.js';
import player from './Player';

export default {
    code: 'safetyPhoto',
    name: 'SAfETy Photo',
    labelField: 'text',
    icon,
    builder,
    builderInitialValues,
    player,
    getDataDefinitions,
};
