import React from 'react';
import PropTypes from 'prop-types';
import { NavBar as BoilerplateNavBar, NavLink } from 'cccisd-laravel-boilerplate';

const Fortress = window.cccisd && window.cccisd.fortress;

/*
 *  In Layouts, this component has been replaced with Laravel Header
 *  However, we still keep it in code because it is the header shown
 *  in Surveys if the "Show Header" option is selected
 */
const NavBar = ({ className }) => {
    const rolesWithManage = ['uberadmin', 'orgAdmin', 'guAdmin', 'siteAdmin', 'instructor'];

    return (
        <BoilerplateNavBar className={className} logo="SAfETy">
            {Fortress && Fortress.auth() && rolesWithManage.includes(Fortress.user.acting.role.handle) && (
                <NavLink to="/manage">Manage</NavLink>
            )}
        </BoilerplateNavBar>
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
